import React, { PropsWithChildren, useEffect } from 'react'
import { useBanquetProps } from 'banquet-runtime-modules'
import { initiate } from '@toasttab/web-analytics'
// @ts-ignore
import envFromHostname from '@toasttab/env-from-hostname'

const AppProviders = ({ children }: PropsWithChildren<unknown>) => {
  const { restaurantInfo, auth } = useBanquetProps()

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const env = envFromHostname(window.location.hostname)

    const domain =
      env === 'prod'
        ? 'https://www.toasttab.com'
        : 'https://preprod.eng.toasttab.com'

    initiate({
      analyticsPrefix: 'data-capital-card-spa',
      analyticsServiceEndpoint: `${domain}/api/service/capital/v1/web-analytics`,
      restaurantId: Number.parseInt(restaurantInfo?.restaurant?.id as string),
      userGuid: auth?.userInfo?.guid as string
    })
  }, [auth?.userInfo?.guid, restaurantInfo?.restaurant?.id])

  return children
}

export default AppProviders
