import { ReactComponentLike } from 'prop-types'
import React from 'react'

interface Config {
  components: Record<
    string,
    {
      wrapper?: ReactComponentLike
      app: ReactComponentLike
    }
  >
  wrapper?: ReactComponentLike
}

export const componentServerInit =
  (config: Config) => (props: Record<string, any>) => {
    const { mode, ...rest } = props
    const componentEntry = config.components[mode]
    const Wrapper = componentEntry.wrapper || config.wrapper
    const Component = componentEntry.app

    if (Wrapper) {
      return (
        <Wrapper {...props}>
          <Component {...rest} />
        </Wrapper>
      )
    } else {
      return <Component {...rest} />
    }
  }
