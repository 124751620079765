import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { componentServerInit } from '@local/component-server'
import { componentServerConfig } from './componentServerConfig'

import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-cap-card',
  rootComponent: componentServerInit(componentServerConfig),
  sentry: {
    publicKey: '1de1e540c2f286708fab70918bc02236@o37442',
    projectId: '4505715981484032',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'capital-card-spa'
