enum UTM_PARAMS {
  CAMPAIGN = 'utm_campaign',
  MEDIUM = 'utm_medium',
  SOURCE = 'utm_source'
}

interface Params {
  campaign?: string
  medium?: string
  source?: string
}

export const addUTMParamsToUrl = (url: string, utmParams: Params): string => {
  // eslint-disable-next-line no-undef
  const base = window.location.origin
  const urlObject = new URL(url, base)
  const queryParams = new URLSearchParams(urlObject.searchParams)

  if (utmParams.campaign) {
    queryParams.set(UTM_PARAMS.CAMPAIGN, utmParams.campaign)
  }
  if (utmParams.medium) {
    queryParams.set(UTM_PARAMS.MEDIUM, utmParams.medium)
  }
  if (utmParams.source) {
    queryParams.set(UTM_PARAMS.SOURCE, utmParams.source)
  }

  return `${url.split('?')[0]}?${queryParams.toString()}`
}
