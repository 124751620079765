import { I18N_CAPITAL_NAMESPACE } from '@local/use-i18n-props'

export const translations = {
  staticPayrollCard: {
    headline: 'Finance your next payroll with Toast Capital',
    description:
      'The application takes a few minutes; if approved, funding is disbursed 1-2 business days after signing your credit agreement.',
    learnMore: 'Learn more'
  }
}

export const initI18n = (i18next: any) => {
  i18next.addResourceBundle(
    'en-US',
    I18N_CAPITAL_NAMESPACE,
    translations,
    true,
    false
  )
}
