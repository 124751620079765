import { useMemo } from 'react'
import { useBanquetProps } from 'banquet-runtime-modules'
import { TFunction } from 'react-i18next'

export const I18N_CAPITAL_NAMESPACE = 'pmts-capital-card-spa'
export const useUseI18NProps = () => {
  const banquetProps = useBanquetProps()
  // @ts-ignore
  const isEmployeeCloud = !!banquetProps?.ec
  // @ts-ignore
  const i18n = isEmployeeCloud ? banquetProps?.ec?.i18n : banquetProps.i18n
  const t: TFunction = useMemo(
    () =>
      isEmployeeCloud
        ? i18n.getFixedT(null, 'pmts-capital-card-spa')
        : i18n.i18next.getFixedT(null, 'pmts-capital-card-spa'),
    // The i18n instance doesn't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return {
    t,
    i18n,
    language: i18n?.language || 'en-US'
  }
}
