import * as React from 'react'
import { Image } from '@toasttab/buffet-pui-image'
import { Button } from '@toasttab/buffet-pui-buttons'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'
import { useUseI18NProps } from '@local/use-i18n-props'
import { addUTMParamsToUrl } from '@local/add-utm-params-to-url'
import { getCapitalDashboardUrl } from '../utils'
import { initI18n } from '../i18n'

export const StaticPayrollCard = () => {
  const { t, i18n } = useUseI18NProps()
  initI18n(i18n)

  // eslint-disable-next-line no-undef
  const learnMoreHref = getCapitalDashboardUrl(window.location.hostname)

  return (
    <div className='px-6 py-4 relative'>
      <div className='absolute right-0 top-0'>
        <Image
          className='z-0'
          src='https://cdn.toasttab.com/static/4a312c3b0f12994e7b1a985f65ddee40b284c89e/projects/capital/static-payroll-card/static-payroll-card-illustration.png'
          alt='background'
        />
      </div>
      <p className='type-default font-semibold w-5/6 mb-3'>
        {t('staticPayrollCard.headline')}
      </p>
      <p className='type-subhead text-secondary w-11/12'>
        {t('staticPayrollCard.description')}
      </p>
      <Button
        as='a'
        variant='link'
        iconRight={<LaunchIcon accessibility='decorative' />}
        className='m-0 !p-0 mt-2'
        href={addUTMParamsToUrl(learnMoreHref, {
          source: 'rd-capital',
          medium: 'toastweb-native',
          campaign: 'customer-capital-payroll-list'
        })}
        target='_blank'
        rel='noreferrer'
      >
        {t('staticPayrollCard.learnMore')}
      </Button>
    </div>
  )
}

